import Vue from 'vue'
import App from './App.vue'
import router from "./router";
import 'lib-flexible'
import store from './store/index'
// import './assets/font/font.css'
import 'vue-area-linkage/dist/index.css';
import {AreaCascader} from 'vue-area-linkage';
import {AreaSelect} from 'vue-area-linkage';
import VCalendar from 'v-calendar'

Vue.config.productionTip = false
Vue.use(AreaCascader)
Vue.use(AreaSelect)
Vue.use(VCalendar,{
    componentPrefix: 'vc'
});

Vue.prototype.$merge = (baseValue, value) => {
    // merge arrays
    if (
        Array.isArray(baseValue)
        && Array.isArray(value)
    ) {
        for (let i = 0; i < value.length; i  ) {
            let val = Vue.prototype.$merge(baseValue[i], value[i]);
            if (val === undefined)
                continue;
            if (baseValue[i])
                Vue.set(baseValue, i, val);
            else
                baseValue.push(val);
        }
    }
    // merge objects
    else if (
        value
        && baseValue
        && typeof value === 'object'
        && typeof object === 'object'
    ) {
        for (let key of Object.keys(value)) {
            let val = Vue.prototype.$merge(baseValue[key], value[key]);
            if (val === undefined)
                continue;
            Vue.set(baseValue, key, val);
        }
    }
    // return value
    else if (value !== undefined)
        return value;
    else
        return baseValue;
};

new Vue({
  render: h => h(App),
  router,
  store
}).$mount('#app')
