import axios from 'axios'

export default {
    namespaced: true,
    state: {
        staff: {
            userId: '',
            email: '',
        }
    },
    getters: {
        getStaff(state){
            return state.staff;
        }
    },
    actions: {
        login(ctx, staff){
            return new Promise((resolve,reject)=>{
                axios.post('/login', {user_id:staff.userId, email: staff.email, source:staff.source||null}).then((rsp)=>{
                    // console.log(res);
                    ctx.commit('saveStaff', staff)
                    resolve(rsp.data)
                }).catch((e)=>{
                    // console.log(e.response.data);
                    reject(e.response.data);
                })
            })
        },
        loadProfile(ctx){
            return new Promise((resolve, reject)=>{
                axios.get('/profile').then((rsp)=>{
                    resolve(rsp.data);
                }).catch((e)=>{
                    reject(e.response.data.message);
                })
            })
        }
    },
    mutations: {
        saveStaff(state, payload){
            // console.log(payload);
            state.staff = payload;
        }
    },
}
