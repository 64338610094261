import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'login',
        component:() => import('../pages/login'),
    },
    {
        path: '/index',
        name: 'index',
        component:() => import('../pages/index'),
    },
    {
        path: '/checkin',
        name: 'checkin',
        component:() => import('../pages/checkin'),
    },
]

const router = new VueRouter({
    routes
})

export default router
