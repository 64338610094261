import Vue from 'vue'
import vuex from 'vuex'
import axios from "axios";

import passport from './passport'

Vue.use(vuex);

//实例state对象
const store = new vuex.Store({
    modules: {
        passport
    },
    //全局状态
    state: {
        staff: {
            tel: '',
            id: '',
            name: '',
            email: '',
            recName: '',
            recTel: '',
            recAddr: '',
            recGift: '',
            recCity: ['北京市', '市辖区', '区域'],

            date: null,
            traffic: '',
            traffic_time: '',

            ids:[
                {name:'', id:''},
                {name:'', id:''},
            ],

            tickets: {
                adult: 0,
                child: 0,
                child2: 0,
                limit: 2
            },

            status: 0,
        },
    },

    getters: {
    },
    actions: {
        getDateRange(ctx){
            return new Promise((resolve,reject)=>{
                axios.get('/date-range?t='+Date.now()).then(rsp=>{
                    resolve(rsp.data);
                }).catch(e=>{
                    reject(e.response.data);
                })
            })
        },
        setStaff(ctx,payload){
            ctx.commit('updateStaff', payload)
        },
        setRecord(ctx, payload){
            console.log(payload);
            ctx.commit('updateRecord', payload)
        }
    },

    //定义处理函数
    mutations: {
        updateStaff(state, payload){
            state.staff.id = payload.staff_id;
            state.staff.email = payload.email;
        },
        updateRecord(state, payload){
            state.staff = Vue.prototype.$merge(state.staff, payload)
        }
    }
});

export default store
